"use client";
import "../globals.scss";
import { Providers } from "@/providers";
export default function AuthLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en">
      <body>
        <Providers>
          <div className="h-screen w-full bg-[#262626] flex justify-center items-center">
            <div className="bg-white shadow-sm">{children}</div>
          </div>
        </Providers>
      </body>
    </html>
  );
}
